import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import "../styles/Blog.scss";
import moment from "moment";
import { TextureComponent } from "../components/slices";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ContextProviderComponent } from "../components/contextProviderComponent";

var initialCountToShow = 3;

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 120,
  },
  select: {
    color: "#17181D",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontWeight: 800,
    letterSpacing: "0.04em",
    height: "48px",
    paddingLeft: "24px",
    background: "#F8F8F8"
  },
  icon: {
    fill: "black",
    marginRight: "20px"
  },
  input: {
    minHeight: "unset"
  },
  menuItem: {
    color: "#17181D",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontWeight: 800,
    letterSpacing: "0.04em"
  }
}));

const intl_en = {
  all: "All",
  perspectives: "Perspectives",
  research: "Research"
};

const intl_de = {
  all: "Alle",
  perspectives: "Perspektiven",
  research: "Forschung"
};

const formatDate = (date) => {
  var dateObj = moment(date, "YYYY-MM-DD");
  return moment(dateObj).format("DD MMMM YYYY");
};
const BlogItem = (blogPost) => {
  const blogPostObj = blogPost.blogPost;
  return (
    <Link to={blogPostObj.url.replace(/\?/g, "")}>
      <div className="blogsPage_blogPosts_post">
        <img className="blogsPage_blogPosts_post_image" src={blogPostObj.data?.mainimage?.url} alt="smartwealth post image" />
        <div className="blogsPage_blogPosts_post_infoRow">
          <span className="blogsPage_blogPosts_post_infoRow_category">{blogPostObj.data?.category?.text}</span>
          <span className="blogsPage_blogPosts_post_infoRow_date">{formatDate(blogPostObj.data?.date)}</span>
        </div>
        <p className="blogsPage_blogPosts_post_text">{blogPostObj.data?.title.text}</p>
      </div>
    </Link>
  );
};

export const Blog = ({ data }) => {
  const [allBlogPosts, setAllBlogPosts] = useState(null);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState(null);
  const [showingBlogPosts, setShowingBlogPosts] = useState(null);
  const [filter, setFilter] = useState("All");
  const [showedPostsCount, setShowedPostsCount] = useState(0);
  const [intl, setIntl] = useState(intl_de);
  const context = useContext(ContextProviderComponent);

  const classes = useStyles();

  useEffect(() => {
    setAllBlogPosts(blogPosts);
    setFilteredBlogPosts(blogPosts);
    setShowingBlogPosts(blogPosts.slice(0, initialCountToShow));
    setIntl(context.lang == "de-ch" ? intl_de : intl_en);
    if (blogPosts.length < initialCountToShow) {
      setShowedPostsCount(blogPosts.length);
    } else {
      setShowedPostsCount(initialCountToShow);
    }
  }, []);

  if (!data) return null;

  const header = data.prismicHeaderitem || {};
  const footer = data.prismicFooteritem || {};
  const { lang, type, url } = header || {};
  const alternateLanguages = header.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages
  };
  const blogPosts = data.allPrismicBlogpost.nodes || {};

  const filterPosts = (filter) => {
    setFilter(filter);
    if (filter === "All") {
      setFilteredBlogPosts(allBlogPosts);
      setShowingBlogPosts(allBlogPosts.slice(0, initialCountToShow));
      if (allBlogPosts.length < initialCountToShow) {
        setShowedPostsCount(allBlogPosts.length);
      } else {
        setShowedPostsCount(initialCountToShow);
      }
    } else {
      var filteredResults = allBlogPosts.filter(
        (item) => String(item.data?.category?.text).toLowerCase() == String(filter).toLowerCase()
      );
      setFilteredBlogPosts(filteredResults);
      setShowingBlogPosts(filteredResults.slice(0, initialCountToShow));
      if (filteredResults.length < initialCountToShow) {
        setShowedPostsCount(filteredResults.length);
      } else {
        setShowedPostsCount(initialCountToShow);
      }
    }
  };

  const showMoreHandler = () => {
    setShowingBlogPosts(filteredBlogPosts);
    setShowedPostsCount(filteredBlogPosts.length);
  };

  return (
    <Layout activeDocMeta={activeDoc} body={header} bodyFooter={footer} fullPage={false}>
      {allBlogPosts && (
        <div className="blogsPage">
          <div className="blogsPage_hero">
            <div className="blogsPage_hero_postsCont">
              <Link to={allBlogPosts[0]?.url.replace(/\?/g, "")}>
                <div
                  className="blogsPage_hero_postsCont_mainPost"
                  style={{ "background-image": `url("${allBlogPosts[0]?.data?.mainimage.url}")` }}>
                  <div className="blogsPage_hero_postsCont_mainPost_textCont">
                    <div className="blogsPage_hero_postsCont_mainPost_textCont_infoRow">
                      <span className="blogsPage_hero_postsCont_mainPost_textCont_infoRow_category">
                        {allBlogPosts[0]?.data?.category?.text}
                      </span>
                      <span className="blogsPage_hero_postsCont_mainPost_textCont_infoRow_date">
                        {formatDate(allBlogPosts[0]?.data?.date)}
                      </span>
                    </div>
                    <p className="blogsPage_hero_postsCont_mainPost_textCont_text">{allBlogPosts[0]?.data?.title.text}</p>
                  </div>
                </div>
              </Link>
              <div className="blogsPage_hero_postsCont_right">
                <Link to={allBlogPosts[1]?.url.replace(/\?/g, "")}>
                  <div className="blogsPage_hero_postsCont_right_post">
                    <div className="blogsPage_hero_postsCont_right_post_textCont">
                      <div className="blogsPage_hero_postsCont_right_post_textCont_infoRow">
                        <span className="blogsPage_hero_postsCont_right_post_textCont_infoRow_category">
                          {allBlogPosts[1]?.data?.category?.text}
                        </span>
                        <span className="blogsPage_hero_postsCont_right_post_textCont_infoRow_date">
                          {formatDate(allBlogPosts[1]?.data?.date)}
                        </span>
                      </div>
                      <p className="blogsPage_hero_postsCont_right_post_textCont_text">{allBlogPosts[1]?.data?.title.text}</p>
                    </div>
                  </div>
                </Link>
                <Link to={allBlogPosts[2]?.url.replace(/\?/g, "")}>
                  <div className="blogsPage_hero_postsCont_right_post">
                    <div className="blogsPage_hero_postsCont_right_post_textCont">
                      <div className="blogsPage_hero_postsCont_right_post_textCont_infoRow">
                        <span className="blogsPage_hero_postsCont_right_post_textCont_infoRow_category">
                          {allBlogPosts[2]?.data?.category?.text}
                        </span>
                        <span className="blogsPage_hero_postsCont_right_post_textCont_infoRow_date">
                          {formatDate(allBlogPosts[2]?.data?.date)}
                        </span>
                      </div>
                      <p className="blogsPage_hero_postsCont_right_post_textCont_text">{allBlogPosts[2]?.data?.title.text}</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="blogsPage_filterRow">
            <div className="blogsPage_filterRow_leftCont">
              <div
                className={`blogsPage_filterRow_leftCont_item ${filter == "All" && "blogsPage_filterRow_leftCont_item-active"}`}
                onClick={() => filterPosts("All")}>
                {intl.all}
              </div>
              <div
                className={`blogsPage_filterRow_leftCont_item  ${filter == "Perspectives" &&
                  "blogsPage_filterRow_leftCont_item-active"}`}
                onClick={() => filterPosts("Perspectives")}>
                {intl.perspectives}
              </div>
              <div
                className={`blogsPage_filterRow_leftCont_item  ${filter == "Research" &&
                  "blogsPage_filterRow_leftCont_item-active"}`}
                onClick={() => filterPosts("Research")}>
                {intl.research}
              </div>
            </div>
          </div>
          <div className="blogsPage_filterRowMobile">
            <FormControl className={classes.formControl}>
              <Select
                className={classes.select}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                onChange={(e) => filterPosts(e.target.value)}
                disableUnderline={true}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    root: classes.input
                  }
                }}>
                <MenuItem value={"All"} className={classes.menuItem} disableUnderline={true}>
                  {intl.all}
                </MenuItem>
                <MenuItem value={"Perspectives"} className={classes.menuItem} disableUnderline={true}>
                  {intl.perspectives}
                </MenuItem>
                <MenuItem value={"Research"} className={classes.menuItem} disableUnderline={true}>
                  {intl.research}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="blogsPage_blogPosts">
            {showingBlogPosts?.map((blogPost) => {
              return <BlogItem blogPost={blogPost} />;
            })}
          </div>
          {filteredBlogPosts?.length > showedPostsCount && (
            <div className="button blogsPage_button" onClick={showMoreHandler}>
              <p>SHOW MORE</p>
            </div>
          )}
        </div>
      )}
      <TextureComponent slice={data.prismicHomepage.data.body[9]} />
    </Layout>
  );
};

export const query = graphql`
  query blogQuery($lang: String) {
    allPrismicBlogpost(filter: { lang: { eq: $lang } }, sort: { order: DESC, fields: data___date }) {
      nodes {
        url
        type
        data {
          metadescription
          metatitle
          author {
            text
          }
          category {
            text
          }
          date
          mainimage {
            url
          }
          maintext {
            text
          }
          minuteread
          title {
            text
          }
        }
        lang
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      lang
      url
      data {
        body {
          ... on PrismicHomepageBodyTextureSection {
            id
            slice_type
            primary {
              buttontext {
                text
              }
              emailtext {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
    }
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
  }
`;

export default Blog;
